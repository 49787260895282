/**
 * Specialty post Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannertestimonial from "../components/bannertestimonial"
import Bannerwithcta from "../components/bannerwithcta"
import Herotype1 from "../components/herotype1"
import Layout from "../components/layout"
import Sectionchecklist from "../components/sectionchecklist"
import Seo from "../components/seo"
import "../styles/templates/specialtypost.scss"


/* Declaration function */
const SpecialtyPostTemplate = ({ data }) => {

  //Const data
  const dataPage = data.wpSpecialty
  const dataAcf = data.wpSpecialty.acfPostSpecialty
  var classPage = dataPage.slug

  //Array for SliderHero
  let arrTextHero = []
  dataAcf.repeaterHeroSpecialty.map(texthero => (
    arrTextHero.push({textHero: texthero.introHeroSpecialty})
  ))

  //Arry for Checklist with CTA
  let arrItemschecklist = []
  dataAcf.repeaterBenefitsSpecialty.map( benefit  => (
    arrItemschecklist.push({textChecklist: benefit.itemBenefitSpecialty})
  ))

  //Data Testimonial
  var extLinkTestimonial = false
  let arrTestimonial = []
  if(dataAcf.ctaLinkTestimonialSpecialty !== null) {
    const dataTestimonial = dataAcf.linkTestimonialSpecialty
    arrTestimonial.push({
      author: dataTestimonial[0].acfPostTestimonials.authorTestimonial,
      content: dataTestimonial[0].acfPostTestimonials.contentTestimonial,
      title: dataTestimonial[0].title,
      video: dataTestimonial[0].acfPostTestimonials.videoLinkTestimonial
    })
    var splitUrlCtaTestimonial= dataAcf.ctaLinkTestimonialSpecialty.split("/")
    var sourceUrlCtaTestimonial = splitUrlCtaTestimonial[0]

    if(sourceUrlCtaTestimonial !== "") {
      extLinkTestimonial = true
    }
  }

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "demo"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        slickslider= "slick"
      />

      {/* Hero */}
      <section className={`section-hero-specialty top-section ${classPage}`}>
        <Herotype1 keyHero={dataPage.title} h1title={dataAcf.titleHeroSpecialty} arrIntro={arrTextHero} colorBar="green" />
      </section>


      {/* Benefits Specialty */}
      <Sectionchecklist
        classSectionChecklist="section-benefits-specialty"
        titleSectionChecklist={dataAcf.titleBenefitsSpecialty}
        textSectionChecklist={dataAcf.textBenefitsSpecialty}
        arrItemschecklist= {arrItemschecklist}
        ctaLabelChecklist={dataAcf.ctaLabelBenefitsSpecialty}
        ctaLinkChecklist={dataAcf.ctaLinkBenefitsSpecialty}
        colorChecklist="green"
        colorThemeSection="green"
        colorCtaList="blue-format"
      />

      {/* Testimonial Specialty*/}
      {arrTestimonial.length > 0 && (
        <section className="section-testimonial-specialty">
          <Bannertestimonial
            titleBannerTestimonial={dataAcf.titleTestimonialSpecialty}
            textBannerTestimonial={dataAcf.textTestimonialSpecialty}
            ctaLabelBannerTestimonial={dataAcf.ctaLabelTestimonialSpecialty}
            ctaLinkBannerTestimonial={dataAcf.ctaLinkTestimonialSpecialty}
            extLinkBannerTestimonial ={extLinkTestimonial}
            arrContentTestimonial={arrTestimonial}
          />
        </section>
      )}

      {/* Banner CTA Benefit */}
      <Bannerwithcta
        classBanner=""
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default SpecialtyPostTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($id: Int!) {
    wpSpecialty(databaseId: {eq: $id}) {
      title
      slug
      acfPostSpecialty {
        ctaLabelBenefitsSpecialty
        ctaLabelTestimonialSpecialty
        ctaLinkBenefitsSpecialty
        ctaLinkTestimonialSpecialty
        linkTestimonialSpecialty {
          ... on WpTestimonial {
            acfPostTestimonials {
              authorTestimonial
              contentTestimonial
              videoLinkTestimonial
            }
            title
          }
        }
        repeaterBenefitsSpecialty {
          itemBenefitSpecialty
        }
        repeaterHeroSpecialty {
          introHeroSpecialty
        }
        textBenefitsSpecialty
        textTestimonialSpecialty
        titleBenefitsSpecialty
        titleHeroSpecialty
        titleTestimonialSpecialty
      }

      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`
